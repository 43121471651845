<button (click)="minus()" mat-icon-button [disabled]="quantity < 2" color="primary">
  <fa-icon icon="minus-circle" size="lg"></fa-icon>
</button>

<input
  type="number"
  min="1"
  matInput
  name="itemQuantity"
  autocomplete="off"
  autocorrect="off"
  [formControl]="quantityControl"
  (change)="quantityChanged()"
  class="quantity"
/>

<button (click)="plus()" mat-icon-button>
  <fa-icon icon="plus-circle" size="lg"></fa-icon>
</button>
