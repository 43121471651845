<h2 mat-dialog-title>Add Order Adjustment</h2>
<form [formGroup]="formGroup">
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>
          <fa-icon icon="comment-alt"></fa-icon>
          Adjustment Reason
        </mat-label>

        <input formControlName="label" matInput placeholder="Adjustment Reason" required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          <fa-icon icon="money-bill-alt"></fa-icon>
          Adjustment Amount
        </mat-label>

        <x-currency-input-control
          formControlName="amount"
          required
          [currency]="data.currency"
        ></x-currency-input-control>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" color="accent" mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" type="button" (click)="update()">Add</button>
</div>
