<ng-container *ngIf="view.data as quickViewData">
  <mat-toolbar>
    <x-order-pill [order]="quickViewData"></x-order-pill>
    <span class="quickview-head-spacer"></span>
    <x-user-pill *ngIf="quickViewData.user" [user]="quickViewData.user"></x-user-pill>
    <span class="quickview-spacer"></span>
  </mat-toolbar>

  <mat-expansion-panel expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon size="lg" icon="shopping-cart"></fa-icon>
        &nbsp;Order Info
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <ng-container matListItemLine>
        <x-order-totals [order]="quickViewData"></x-order-totals>
        <br />
        <table class="mat-typography cart-summary-table">
          <tbody>
            <tr>
              <th>Source:</th>
              <td>{{ quickViewData.source }}</td>
            </tr>
            <tr>
              <th>Checkout At:</th>
              <td>{{ quickViewData.checkoutAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Created At:</th>
              <td>{{ quickViewData.createdAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Updated At:</th>
              <td>{{ quickViewData.updatedAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Quoted At:</th>
              <td>{{ quickViewData.quotedAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Canceled At:</th>
              <td>{{ quickViewData.canceledAt | datetime: 'short' }}</td>
            </tr>
            <tr *ngIf="quickViewData.authorUser">
              <th>Authored By:</th>
              <td>{{ quickViewData.authorUser.email }}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <div class="d-flex">
          <div>
            <span class="mat-body-2">Gift Message:</span>
            <br />
            <span *ngIf="quickViewData.giftMessage; else noGiftMessage" class="mat-caption">
              {{ quickViewData.giftMessage }}
            </span>
            <ng-template #noGiftMessage><span class="mat-caption">NONE</span></ng-template>
          </div>
        </div>
        <br />
        <div class="d-flex">
          <div>
            <span class="mat-body-2">Instructions:</span>
            <br />
            <span *ngIf="quickViewData.instructions; else noInstructions" class="mat-caption">
              {{ quickViewData.instructions }}
            </span>
            <ng-template #noInstructions><span class="mat-caption">NONE</span></ng-template>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <!-- <mat-expansion-panel expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon size="lg" icon="user"></fa-icon>
        &nbsp;Customer Info
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <span class="mat-body-2">User Order Counts</span>
      <br />
      <table class="mat-typography product-summary-table">
        <tbody>
          <tr *ngFor="let stateCount of quickViewData.user.orderStateCounts">
            <td style="text-align: left">
              {{ 'OrderState.' + stateCount.state | l }}
            </td>
            <td>{{ stateCount.count }}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <div class="d-flex">
        <div>
          <span class="mat-body-2">Memberships</span>
          <br />
          <ng-container *ngIf="quickViewData.user.members.length > 0; else noMembers">
            <x-pill *ngFor="let member of quickViewData.user.members">
              {{ member.membership.name }}
            </x-pill>
          </ng-container>
          <ng-template #noMembers>NONE</ng-template>
        </div>
      </div>
    </ng-template>
  </mat-expansion-panel> -->

  <mat-expansion-panel expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon size="lg" icon="stroopwafel"></fa-icon>
        &nbsp;Products
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <table class="mat-typography product-summary-table">
        <tbody>
          <tr *ngFor="let item of quickViewData.items">
            <td style="text-align: left">
              {{ item.variant.stockItem?.sku }}
            </td>
            <td style="text-align: left">
              {{ item.productName }}
            </td>
            <td>{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon icon="credit-card"></fa-icon>
        &nbsp;Payment
      </mat-panel-title>
      <mat-panel-description>
        <x-order-payment-pill [order]="quickViewData"></x-order-payment-pill>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <ng-container
        *ngIf="quickViewData.payments.length === 0 && quickViewData.walletTransactions.length === 0"
      >
        No payments or wallet transactions associated with this order
      </ng-container>

      <ng-container *ngFor="let payment of quickViewData.payments">
        <table class="mat-typography cart-summary-table">
          <tbody>
            <tr>
              <th>Payment ID</th>
              <td>{{ payment.id }}</td>
            </tr>
            <tr>
              <th>Payment State</th>
              <td>{{ payment.state }}</td>
            </tr>
            <tr>
              <th>Payment Amount</th>
              <td>{{ payment.amount | money: payment.currency }}</td>
            </tr>
            <tr>
              <th>Refunded Amount</th>
              <td>{{ payment.refundedAmount | money: payment.currency }}</td>
            </tr>
            <tr>
              <th>Refunded At</th>
              <td>{{ payment.refundedAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{{ payment.description }}</td>
            </tr>
            <tr *ngIf="payment.errorDescription">
              <th>Error</th>
              <td>{{ payment.errorDescription }}</td>
            </tr>
            <tr>
              <th>Reference</th>
              <td>{{ payment.reference }}</td>
            </tr>
            <tr>
              <th>Payment Method</th>
              <td>{{ payment.method.name }}</td>
            </tr>
            <tr>
              <th>Payment Details Saved</th>
              <td>{{ payment.save ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th>Created At</th>
              <td>{{ payment.createdAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Updated At</th>
              <td>{{ payment.updatedAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Finalized At</th>
              <td>{{ payment.finalizedAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Public Attributes</th>
              <td>
                <pre style="max-width: 240px; overflow-x: auto; text-align: left">{{
                  payment.publicAttributes | json
                }}</pre>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-container>

      <ng-container *ngFor="let transaction of quickViewData.walletTransactions">
        <table class="mat-typography cart-summary-table">
          <tbody>
            <tr>
              <th>Transaction ID</th>
              <td>{{ transaction.id }}</td>
            </tr>
            <tr>
              <th>Transaction State</th>
              <td>{{ transaction.state }}</td>
            </tr>
            <tr>
              <th>Transaction Description</th>
              <td>{{ transaction.description }}</td>
            </tr>
            <tr>
              <th>Created At</th>
              <td>{{ transaction.createdAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Updated At</th>
              <td>{{ transaction.updatedAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Finalized At</th>
              <td>{{ transaction.finalizedAt | datetime: 'short' }}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>{{ transaction.amount | money: transaction.wallet.currency }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <mat-expansion-panel expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon size="lg" icon="bullhorn"></fa-icon>
        &nbsp;Promotions
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <table class="mat-typography product-summary-table">
        <tbody *ngIf="quickViewData.promotions.length > 0; else noPromotions">
          <tr *ngFor="let promotion of quickViewData.promotions">
            <th>{{ promotion.name }}</th>
            <td>ID {{ promotion.id }}</td>
          </tr>
        </tbody>
        <ng-template #noPromotions>NONE</ng-template>
      </table>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <x-sage-order-sync-panel
    *ngIf="quickViewData.number as number"
    [number]="number"
  ></x-sage-order-sync-panel>

  <mat-divider></mat-divider>
</ng-container>
