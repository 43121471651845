import { Component, Input } from '@angular/core';
import { CartObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-order-totals',
  templateUrl: './order-totals.component.html',
  styleUrls: ['./order-totals.component.scss'],
})
export class OrderTotalsComponent {
  @Input()
  order: CartObject;
}
