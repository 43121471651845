import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'x-cart-quantity-control',
  templateUrl: './cart-quantity-control.component.html',
  styleUrls: ['./cart-quantity-control.component.scss'],
})
export class CartQuantityControlComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  @Input()
  set quantity(q: number) {
    this.quantityControl.patchValue(q, { emitEvent: false });
  }
  get quantity() {
    return this.quantityControl.value;
  }

  @Output()
  quantityUpdated = new EventEmitter<number>();

  quantity$: Subject<number> = new Subject();

  quantityControl = new UntypedFormControl(1);

  ngOnInit(): void {
    this.quantity$
      .pipe(
        takeUntil(this._destroy$),
        tap((quantity) => {
          if (this.quantity !== quantity) {
            this.quantity = quantity;
          }
        }),
        debounceTime(500),
        tap((quantity) => {
          this.quantityUpdated.emit(quantity);
        }),
      )
      .subscribe();
  }

  plus() {
    this.quantity++;
    this.quantity$.next(this.quantity);
  }

  minus() {
    if (this.quantity > 1) {
      this.quantity--;
      this.quantity$.next(this.quantity);
    }
  }

  quantityChanged() {
    if (this.quantity < 1) this.quantity = 1;

    this.quantity$.next(this.quantity);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
