import { Component, Input } from '@angular/core';
import { DataView } from '@x/common/data';
import { IOrderDetailObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-order-quick-view',
  templateUrl: './order-quick-view.component.html',
  styleUrls: ['./order-quick-view.component.scss'],
})
export class OrderQuickViewComponent {
  @Input()
  view: DataView<IOrderDetailObject, number>;
}
